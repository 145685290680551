.shopewell-alert {
  position: fixed;
  /* margin-left: 20%; */
  inset: 0;
  background-color: rgba(0, 0, 0, 0.4);
  /* backdrop-filter: blur(1px); */
  z-index: 11160;
  border: #868484;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.shopewell-alert-content {
  padding: 20px;
  font-weight: 600;
  /* font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif; */
}
.shopewell-alert-box {
  background-color: white;
  width: 40%;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
}
.shopewell-alert-btn-container {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  background-color: whitesmoke;
  padding: 10px 20px;
}
.shopewell-alert-ok,
.shopewell-alert-cancel {
  border: none;
  cursor: pointer;
  border-radius: 2px;
  font-size: 0.9rem;
  padding: 10px 15px;
  font-weight: 500;
  color: white;
}
.shopewell-alert-ok {
  background-color: #e20202;
}
.shopewell-alert-cancel {
  background-color: #868484;
}
