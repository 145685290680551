:root {
  --red: #f40000;
  --bg-card: #f6f5f8;
  --bg-primary: #f2f3f8;
  --bg-white: #ffffff;
  --border-gray: #d8dfeb;
  --font-black: #000000;
  --border-black: #000000;
  --button-gray: #d9d9d9;
  --dark-gray: #4d575d;
  --radio-black: #383b41;
  --font-white: #ffffff;
  --bg-blue: #0095f7;
  --bg-forth: #f0f2f5;
  --table-head: #d9d9d9;
  --green-color: #178015;
  --green-yellow: #ffff00;
  --card-bg-gray: #6a6a6a;
  --light-red-color: #fd7a7a;
  --bg-secondary: #f0f2f5;
  --bg-third: #e4e6eb;
  --color-secondary: #65676b;
  --divider: #ced0d4;
  --bg-color: #f5f5f5;
  --dark-color-secondary: #b0b3b8;
  --light-gray: #d2d2d2;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.main{
  height: 100vh;
}